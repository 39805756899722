var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title-block"},[_c('span',{staticClass:"page-title",attrs:{"id":"title"}},[_vm._v("Procuradores")]),_c('v-breadcrumbs',{staticClass:"app-breadcrumb",attrs:{"divider":"›","items":[
                        {
                            text: 'Homepage',
                            disabled: false,
                            to: '/home',
                            exact: true,
                        },
                        {
                            text: 'Procuradores',
                            disabled: true,
                            exact: true,
                        } ]}})],1),_c('div',{staticClass:"button-group"},[_c('v-btn',{attrs:{"id":"btnNew","type":"button","color":"primary","elevation":"0","to":"/procuradores/novo"}},[_vm._v("Incluir")])],1)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"filter-card"},[_c('v-card-title',{staticClass:"filter-card-title"},[_vm._v("Filtro")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.load()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","id":"name","label":"Nome","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.name),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "name", $$v)},expression:"dataList.filter.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","id":"federal_registration","label":"Carteira OAB","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.federal_registration),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "federal_registration", $$v)},expression:"dataList.filter.federal_registration"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"id":"btnFilter","type":"submit","color":"primary","dark":"","elevation":"2"}},[_vm._v("Filtrar")])],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"table-card"},[_c('v-card-title',{staticClass:"table-card-title d-flex justify-space-between"},[_vm._v("Procuradores "),_c('v-spacer'),_c('div',{staticClass:"icon-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"icon":"","size":"large"},on:{"click":function($event){return _vm.load(true)}}},on),[_vm._v(" mdi-microsoft-excel ")])]}}])},[_c('span',[_vm._v("Gerar lista de procuradores - CSV")])])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"server-items-length":_vm.dataList.count,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhum procurador foi encontrado","loading-text":"Carregando dados...","options":_vm.dataList.options,"footer-props":_vm.dataList.footerOptions},on:{"update:items-per-page":_vm.pageSizeChanged,"update:page":_vm.pageChanged,"update:options":function($event){return _vm.$set(_vm.dataList, "options", $event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.created_at,"DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name && item.name ? item.name : 'N/A')+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"me-2",attrs:{"disabled":!_vm.$check('users/1', ['PATCH']),"size":"large"},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-pencil-outline ")]),_c('v-icon',{staticClass:"me-2",attrs:{"disabled":!_vm.$check('users/1', ['DELETE']),"size":"large"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }