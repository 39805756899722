import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";

import UserList from "@/views/user/UserList.vue";
import UserDetails from "@/views/user/UserDetails.vue";

import AdminList from "@/views/admin/AdminList.vue";
import AdminDetails from "@/views/admin/AdminDetails.vue";

import EventList from "@/views/event/EventList.vue";
import EventDetails from "@/views/event/EventDetails.vue";
import EventBulkAnalysis from "@/views/event/BulkAnalysis.vue";
import EventCreate from "@/views/event/EventCreate.vue";
// import AccreditationList from "@/views/accreditation/AccreditationList.vue";
// import AccreditationDetails from "@/views/accreditation/AccreditationDetails.vue";

import ClientList from "@/views/client/ClientList.vue";
import ClientDetails from "@/views/client/ClientDetails.vue";

import CreditorsList from "@/views/creditors-list/CreditorsList.vue";
import CreditorsInclude from "@/views/creditors-list/CreditorsListDetails.vue";


import EmployeeList from "@/views/employee/EmployeeList.vue";
import EmployeeDetails from "@/views/employee/EmployeeDetails.vue";

import IncidentList from "@/views/judicial-incident/IncidentList.vue";
import IncidentDetails from "@/views/judicial-incident/IncidentDetails.vue";

import LetterAnalysisList from "@/views/letter-analysis/LetterAnalysisList.vue";
import LetterAnalysisDetails from "@/views/letter-analysis/LetterAnalysisDetails.vue";

import QgcList from "@/views/qgc/QgcList.vue";
import QgcDetails from "@/views/qgc/QgcDetails.vue";

import ClientDocList from "@/views/client-doc/ClientDocList.vue";
import ClientDocDetails from "@/views/client-doc/ClientDocDetails.vue";

import FaqList from "@/views/faq/FaqList.vue";
import FaqDetails from "@/views/faq/FaqDetails.vue";

import GeneralMeetingList from "@/views/general-meeting/GeneralMeetingList.vue";
import GeneralMeetingDetails from "@/views/general-meeting/GeneralMeetingDetails.vue";
import GeneralMeetingVoting from "@/views/general-meeting/GeneralMeetingVoting.vue";
import GeneralMeetingPresence from "@/views/general-meeting/GeneralMeetingPresence.vue";
import VotingList from "@/views/general-meeting/VotingList.vue";
import VotingDetails from "@/views/general-meeting/VotingDetails.vue";

import DistrictList from "@/views/district/DistrictList.vue";
import DistrictDetails from "@/views/district/DistrictDetails.vue";

import PostList from "@/views/post/PostList.vue";
import PostDetails from "@/views/post/PostDetails.vue";

import DynamicTextList from "@/views/dynamic-text/DynamicTextList.vue";
import DynamicTextDetails from "@/views/dynamic-text/DynamicTextDetails.vue";

import DynamicInfoList from "@/views/dynamic-info/DynamicInfoList.vue";
import DynamicInfoDetails from "@/views/dynamic-info/DynamicInfoDetails.vue";

import TemplateDocList from "@/views/template-doc/TemplateDocList.vue";
import TemplateDocDetails from "@/views/template-doc/TemplateDocDetails.vue";

import ProcuratorList from "@/views/procurator/ProcuratorList.vue";
import ProcuratorDetails from "@/views/procurator/ProcuratorDetails.vue";

import MostConsultedList from "@/views/most-consulted-process/MostConsultedList.vue";

import HomeBannerList from "@/views/home-banner/HomeBannerList.vue";
import HomeBannerDetails from "@/views/home-banner/HomeBannerDetails.vue";

import adminProfiles from "@/common/data/admin-profiles.data";

import * as jwt from "jwt-decode";

//the route guard
const guard = function(to: any, from: any, next: any) {
  const token = localStorage.getItem("token");

  // const checked = this.$check(to.name, 'GET');

  
  const publicPath = to.matched.find(
    (path) => path.name === "Public" || path.name === "Home"
    );
    if (!token && to.path !== "/login" && !publicPath) {
      next({
        path: "/login",
      });
    } else {

      console.log('to.name ', to.name)

      if(to.name == "Home") {
        next();
        return;
      }

      const resolvedToken = jwt(token);
      
      const profile = adminProfiles.find((x) => x.id === resolvedToken.data.profile);

      if(profile.permissions && profile.permissions[0] === '*') return next();

      const checkPermission = profile.permissions.find((x) => x.path === to.name);

      if(!checkPermission) {
        next({
          path: "/home",
        });
        return;
      }

      const checkMethod = checkPermission.method.includes('GET')

      if(!checkMethod) {
        next({
          path: "/home",
        });
        return;
      }

      next();
    }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/Public.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/",
        redirect: "/login",
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/Private.vue"),
    beforeEnter: guard,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        beforeEnter: guard,
      },
      {
        path: "/credores",
        name: "User",
        component: UserList,
        beforeEnter: guard,
      },
      {
        path: "/credores/:id",
        name: "User",
        component: UserDetails,
        beforeEnter: guard,
      },
      {
        path: "/admins",
        name: "Admin",
        component: AdminList,
        beforeEnter: guard,
      },
      {
        path: "/admins/:id",
        name: "Admin",
        component: AdminDetails,
        beforeEnter: guard,
      },
      {
        path: "/textos-automaticos",
        name: "DynamicText",
        component: DynamicTextList,
        beforeEnter: guard,
      },
      {
        path: "/textos-automaticos/:id",
        name: "DynamicText",
        component: DynamicTextDetails,
        beforeEnter: guard,
      },
      {
        path: "/divergencias",
        name: "Event",
        component: EventList,
        beforeEnter: guard,
      },
      {
        path: "/divergencias/:id",
        name: "Event",
        component: EventDetails,
        beforeEnter: guard,
      },
      {
        path: "/event-create",
        name: "Event",
        component: EventCreate,
        beforeEnter: guard,
      },
      {
        path: "/analise-massa",
        name: "Event",
        component: EventBulkAnalysis,
        beforeEnter: guard,
      },
      {
        path: "/modelo-documentos",
        name: "TemplateDoc",
        component: TemplateDocList,
        beforeEnter: guard,
      },
      {
        path: "/modelo-documentos/:id",
        name: "TemplateDoc",
        component: TemplateDocDetails,
        beforeEnter: guard,
      },
      // {
      //   path: "/credenciamentos",
      //   name: "Accreditation",
      //   component: AccreditationList,
      //   beforeEnter: guard,
      // },
      // {
      //   path: "/credenciamentos/:id",
      //   name: "accreditation",
      //   component: AccreditationDetails,
      //   beforeEnter: guard,
      // },
      {
        path: "/textos-dinamicos",
        name: "DynamicInfo",
        component: DynamicInfoList,
        beforeEnter: guard,
      },
      {
        path: "/textos-dinamicos/:id",
        name: "DynamicInfo",
        component: DynamicInfoDetails,
        beforeEnter: guard,
      },
      {
        path: "/casos",
        name: "Client",
        component: ClientList,
        beforeEnter: guard,
      },
      {
        path: "/lista-credores/incluir",
        name: "Creditors",
        component: CreditorsInclude,
        beforeEnter: guard,
      },
      {
        path: "/lista-credores",
        name: "Creditors",
        component: CreditorsList,
        beforeEnter: guard,
      },
      {
        path: "/casos/:id",
        name: "Client",
        component: ClientDetails,
        beforeEnter: guard,
      },
      {
        path: "/documentos",
        name: "Document",
        component: ClientDocList,
        beforeEnter: guard,
      },
      {
        path: "/documentos/:id",
        name: "Document",
        component: ClientDocDetails,
        beforeEnter: guard,
      },
      {
        path: "/faqs",
        name: "Faq",
        component: FaqList,
        beforeEnter: guard,
      },
      {
        path: "/faqs/:id",
        name: "Faq",
        component: FaqDetails,
        beforeEnter: guard,
      },
      {
        path: "/agcs",
        name: "GeneralMeeting",
        component: GeneralMeetingList,
        beforeEnter: guard,
      },
      {
        path: "/agcs/:id",
        name: "GeneralMeeting",
        component: GeneralMeetingDetails,
        beforeEnter: guard,
      },
      {
        path: "/procuradores",
        name: "Procurator",
        component: ProcuratorList,
        beforeEnter: guard,
      },
      {
        path: "/procuradores/:id",
        name: "Procurator",
        component: ProcuratorDetails,
        beforeEnter: guard,
      },
      // {
      //   path: "/agcs/votacao",
      //   name: "GeneralMeetingVoting",
      //   component: GeneralMeetingVoting,
      //   beforeEnter: guard,
      // },
      {
        path: "/agcs/votacao/:id",
        name: "GeneralMeeting",
        component: GeneralMeetingVoting,
        beforeEnter: guard,
      },
      {
        path: "/agcs/presenca/:id",
        name: "GeneralMeeting",
        component: GeneralMeetingPresence,
        beforeEnter: guard,
      },
      {
        //path: "/votacao/:id?",
        path: "/votacao",
        name: "VotingDetails",
        component: VotingDetails,
        //props: (route) => ({ id: route.params.id, clientId: route.query.clientId }),
        beforeEnter: guard,
      },
      {
        path: "/votacao-lista",
        name: "VotingList",
        component: VotingList,
        beforeEnter: guard,
      },
      {
        path: "/comarcas",
        name: "District",
        component: DistrictList,
        beforeEnter: guard,
      },
      {
        path: "/comarcas/:id",
        name: "District",
        component: DistrictDetails,
        beforeEnter: guard,
      },
      {
        path: "/blog/posts",
        name: "Blog",
        component: PostList,
        beforeEnter: guard,
      },
      {
        path: "/blog/posts/:id",
        name: "Blog",
        component: PostDetails,
        beforeEnter: guard,
      },
      {
        path: "/colaboradores",
        name: "Employee",
        component: EmployeeList,
        beforeEnter: guard,
      },
      {
        path: "/colaboradores/:id",
        name: "Employee",
        component: EmployeeDetails,
        beforeEnter: guard,
      },
      {
        path: "/incidentes",
        name: "JudicialIncidents",
        component: IncidentList,
        beforeEnter: guard,
      },
      {
        path: "/incidentes/:id",
        name: "JudicialIncidents",
        component: IncidentDetails,
        beforeEnter: guard,
      },
      {
        path: "/analise-oficio",
        name: "LetterAnalysis",
        component: LetterAnalysisList,
        beforeEnter: guard,
      },
      {
        path: "/analise-oficio/:id",
        name: "LetterAnalysis",
        component: LetterAnalysisDetails,
        beforeEnter: guard,
      },
      {
        path: "/qgc",
        name: "Qgc",
        component: QgcList,
        beforeEnter: guard,
      },
      {
        path: "/qgc/:id",
        name: "Qgc",
        component: QgcDetails,
        beforeEnter: guard,
      },
      {
        path: "/processos-mais-consultados",
        name: "MostConsulted",
        component: MostConsultedList,
        beforeEnter: guard,
      },
      {
        path: "/banner-aviso",
        name: "HomeBanner",
        component: HomeBannerList,
        beforeEnter: guard,
      },
      {
        path: "/banner-aviso/:id",
        name: "HomeBanner",
        component: HomeBannerDetails,
        beforeEnter: guard,
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
