<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Incluir Lista</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Incluir Lista',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" type="button" color="primary" elevation="0" @click="uploadFile"
                                    :loading="dataset.loading">Enviar
                                    lista</v-btn>
                            </template>
                            <span>Enviar arquivo para processamento</span>
                        </v-tooltip>
                        <v-btn id="btnGoBack" type="button" color="primary" elevation="0" outlined
                            to="/lista-credores">Voltar</v-btn>
                    </div>
                </div>

            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes
                                    <v-spacer></v-spacer>
                                    <div class="icon-container">
                                        <v-tooltip bottom>
                                            <template #activator="{ on }">
                                                <v-btn v-on="on" icon @click="showHelpModal = true">
                                                    <v-icon>mdi-help-circle-outline</v-icon>
                                                </v-btn>
                                            </template>
                                                <span>Clique para ver detalhamento do arquivo</span>
                                        </v-tooltip>
                                    </div> 
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete v-model="dataset.data.client_id" :items="dataset.clients" dense
                                                item-text="name" item-value="id" outlined hide-details="auto"
                                                :rules="[$rules.required]" label="Caso" no-data-text="Nenhum caso encontrado"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field id="email" type="email"
                                                label="E-mail (recebimento de notificação)" dense
                                                v-model="dataset.data.email" :rules="[$rules.requried, $rules.email]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-file-input dense accept=".csv" prepend-icon="" outlined counter required
                                                :rules="[$rules.required, $rules.fileSize(130), (value) => validateCSVFile(value) || 'O arquivo deve estar no formato CSV']"
                                                hint="O arquivo não pode ser superior a 130MB" hide-details="auto"
                                                label="Lista de credores" :clearable="true"
                                                @change="onFileSelected($event)">
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="dataset.progress">
                                        <v-col cols="12">
                                            <v-progress-linear v-model="dataset.progress" color="green" height="25"
                                                reactive>
                                                <strong>{{ dataset.progress }} %</strong>
                                            </v-progress-linear>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="dataset.errorData.length">
            <v-col cols="12">
                <v-card class="card-form-outlined" color="gray">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title font-weight-bold">Erros encontrados</span>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text v-for="(item, index) in dataset.errorData" :key="index">
                        <v-row>
                            <v-col>
                                <span color="primary">
                                    Linha: <strong>{{ item.row }}</strong> </span> Erro: <strong>{{ item.message }}</strong>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-dialog v-model="showHelpModal" max-width="1100">
            <v-card>
                <v-card-title class="text-h6" >Formato do Arquivo</v-card-title>
                <v-divider></v-divider>
                <br>
                <v-card-text>
                    <p>O arquivo deve estar no formato CSV (Campos separados por "<strong>;</strong>"). Ex.: teste.csv</p>
                    <p>O cabeçalho deve conter as seguintes colunas, <strong>nessa ordem:</strong></p>
                    <ul>
                        <li><strong>Nome</strong>: nome do credor. </li>
                        <p> Ex.: Joao Pedro</p>
                        <li><strong>CPF/CNPJ</strong>: CPF/CNPJ do credor (com ou sem pontos, barra e traço). </li>
                        <p>Ex.: CPF (12345678901 ou 123.456.789-01) - CNPJ (12345678000134 ou 12.345.678/0001-34)</p>
                        <p>OBS.: CPF deve conter 11 digitos e CNPJ 14 digitos, mesmo se houver "zeros" à esquerda.</p>
                        <li><strong>Valor</strong>: valor do crédito (sem formatação e 2 casas decimais ou inteiro). </li>
                        <p>Ex.: 350 ou 350,00 / 350,90</p>
                        <li><strong>E-mail</strong>: e-mail do credor. </li>
                        <p>Ex.: teste@teste.com</p>
                        <li><strong>Telefone</strong>: número do telefone do credor. </li>
                        <p>Ex.: 5199990101</p>
                        <li><strong>Classe</strong>: classe do credor. </li>
                        <p>Ex.: Trabalhista / Garantia Real / Quirografarios / ME/EPP / Extraconcursais</p>
                    </ul>
                    <p><strong>Cabeçalho:</strong> <code>Nome | CPF/CNPJ | Valor | E-mail | Telefone | Classe</code></p>

                    <v-divider></v-divider>

                    <br>
                    <p><strong>Exemplo de registros de um arquivo válido:</strong></p>

                    <div class="example-row">
                        <div class="example-col">Nome</div>
                        <div class="example-col">CPF/CNPJ</div>
                        <div class="example-col">Valor</div>
                        <div class="example-col">E-mail</div>
                        <div class="example-col">Telefone</div>
                        <div class="example-col">Classe</div>
                    </div>
                    <div class="example-row">
                        <div class="example-col">Deanna Ellis</div>
                        <div class="example-col">880.732.328-12</div>
                        <div class="example-col">6292,29</div>
                        <div class="example-col">deanna@example.com</div>
                        <div class="example-col">5199990101</div>
                        <div class="example-col">Trabalhista</div>
                    </div>
                    <div class="example-row">
                        <div class="example-col">Bailey Carlson</div>
                        <div class="example-col">30436901412</div>
                        <div class="example-col">5982,00</div>
                        <div class="example-col">bailey@example.com</div>
                        <div class="example-col">5199990102</div>
                        <div class="example-col">Garantia Real</div>
                    </div>
                    <div class="example-row">
                        <div class="example-col">Matthew Hampton</div>
                        <div class="example-col">69.972.491/0001-39</div>
                        <div class="example-col">5142</div>
                        <div class="example-col">matthew@example.com</div>
                        <div class="example-col">5199990103</div>
                        <div class="example-col">Quirografarios</div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showHelpModal = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
  

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

Vue.use(VueLodash)

export default {
    name: "upload-files",
    data() {
        return {
            dataset: {
                data: {},
                progress: null,
                loading: false,
                errorData: [],
                clients: []
            },
            showHelpModal: false,
            dataForm: {
                validForm: true,
            },
        }
    },

    created() {
        this.loadClients()
    },

    methods: {

        goBack() {
            this.$router.replace("/lista-credores");
        },

        onFileSelected(file) {
            this.dataset.progress = 0;
            this.dataset.data.file = file;
            this.dataset.errorData = [];
        },

        validateCSVFile(file) {
            
           return file && file.name && file.name.endsWith('.csv');
           
        },


        async loadClients() {
            
            try {
               const result = await axios.get(`/api/clients`, { params: { start: 0, limit: 100000, search: {} }});

                const data = result.data.content.data;

                data.sort((a, b) => a.name.localeCompare(b.name));

                this.dataset.clients = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async uploadFile() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja enviar a lista de credores?',
                title: 'Lista de credores',
                actions: {
                    false: 'Cancelar',
                    true: 'Enviar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;
            this.dataset.progress = 0;

            try {

                const payload = this.buildPayload()

                const response = await axios.post('/api/admin/creditor-lists/upload', payload, {
                    onUploadProgress: (event) => {
                        this.dataset.progress = Math.round((100 * event.loaded) / event.total);
                    }
                })

                this.dataset.errorData = response.data.content.errors

                if (this.dataset.errorData.length) {
                    this.$dialog.notify.error("Encontramos alguns erros na lista enviada, verifique-os abaixo!", { position: "top-right", timeout: 3000 });

                    return
                }

                this.$dialog.notify.info("Lista de credores enviada com sucesso, aguarde novas atualizações", { position: "top-right", timeout: 3000 });

                this.goBack()

            } catch (error) {
                console.log('retorno de erro ', error.message)

                const errorMessage = error.response.data.error.message || error || 'Ocorreu um erro ao enviar o arquivo!';

                this.$dialog.notify.error(errorMessage, {
                    position: 'top-right',
                    timeout: 5000
                });
               
            } finally {
                this.dataset.loading = false;
            }
        },

        buildPayload() {
            const formData = new FormData();

            formData.append('name', this.dataset.data.name);
            formData.append('email', this.dataset.data.email);
            formData.append('client_id', this.dataset.data.client_id);
            formData.append('file', this.dataset.data.file);

            return formData;
        }

    }

};
</script>

<style>
.example-row {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.example-col {
  flex: 1;
  padding: 8px;
}
.example-col:first-child {
  flex: 2;
}
</style>