<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/procuradores">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>

                                    <v-row>
                                        <v-col>
                                            <v-text-field dense id="name" label="Nome procurador" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field dense id="federal_registration" label="Número OAB" v-model="dataset.data.federal_registration" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.statesList"
                                                label="OAB UF" v-model="dataset.data.federal_registration_uf" item-value="name"
                                                item-text="name" :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field dense id="procurator_email" label="E-mail" v-model="dataset.data.email" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col >
                                            <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                                                counter :rules="[ $rules.fileSize(4)]" show-size
                                                hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                                                label="Documento OAB" :clearable="true" @change="onProcuratorSelected($event)" ref="procurator_file">
                                            </v-file-input>
        
                                        </v-col>
                                    </v-row>

                                    <template v-if="dataset.data.doc_url">
                                        <!-- <v-row class="mt-n5" >
                                            <v-col class="doc-info">
                                            <span >Documento atual</span>  
                                            </v-col>
                                        </v-row>  
                                        <v-row class="mt-n3">
                                            <v-col>  
                                                <iframe
                                                    :src="dataset.data.doc_url"
                                                    width="250"
                                                    height="150"
                                                    frameborder="0"
                                                    scrolling="no" class="rounded-xl"
                                                ></iframe>

                                            </v-col>
                                        </v-row> -->
                                        <v-row class="mt-n5">
                                            <v-col cols="12">
                                                <v-btn type="button" elevation="0" color="primary" outlined :href="dataset.data.doc_url"
                                                    target="_blank">Visualizar documento
                                                </v-btn> 
                                            </v-col>
                                        </v-row>
                                    </template>

                                </v-card-text>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>

<script>

import axios from "axios"
import statesList from "@/common/data/states-list.data"

export default {
    name: "ProcuratorDetails",

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            data: {},
            modalResult: null,
            statesList: statesList,
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
    }),

    created() {
        this.loadData()
    },
   
    methods: {
        goBack() {
            this.$router.replace("/procuradores");
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();
            await this.load();
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = `${this.page.isNew ? 'Novo procurador' : 'Detalhes do procurador'}`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Procuradores",
                disabled: false,
                to: "/procuradores",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.title,
                disabled: true,
                exact: true,
            });
        },

        onProcuratorSelected(procurator_file) {
            this.dataset.data.procurator_file = procurator_file;
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/lawyer/${this.$route.params.id}`);
                

                const data = result.data.content;

                this.dataset.data = data;

                console.log('data: ', data)
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o procurador?',
                title: 'Incluir procurador',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/lawyer', payload);

                this.$dialog.notify.info("Procurador salvo com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar dados do procurador?',
                title: 'Atualizar dados procurador',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/lawyer/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Procurador atualizado com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

       
        buildPayload() {
            const formData = new FormData()

            if (this.dataset.data.name)
                formData.append('name', this.dataset.data.name);

            if (this.dataset.data.federal_registration)
                formData.append('federal_registration', this.dataset.data.federal_registration)

            if (this.dataset.data.federal_registration_uf)
                formData.append('federal_registration_uf', this.dataset.data.federal_registration_uf)

            if (this.dataset.data.email)
                formData.append('email', this.dataset.data.email)

            if (this.dataset.data.procurator_file)
                formData.append('procurator_file', this.dataset.data.procurator_file)

            return formData;
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>