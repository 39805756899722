<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›"
                            :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <div v-if="dataset.tabIndex == 1">
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-btn v-on="on" id="btnSave" type="button" elevation="0" color="primary" dark
                                        @click="add" :loading="dataset.loading" v-if="page.isNew">Salvar</v-btn>
                                </template>
                                <span>Salvar caso</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-btn v-on="on" id="btnSave" type="button" elevation="0" color="primary" dark
                                        @click="update" :loading="dataset.loading" v-if="!page.isNew">Salvar</v-btn>
                                </template>
                                <span>Salvar caso</span>
                            </v-tooltip>
                        </div>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnBack" type="button" elevation="0" color="primary" outlined
                                    to="/casos">Voltar</v-btn>
                            </template>
                            <span>Voltar à lista de casos</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="!page.isNew">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="button-group">
                        <v-btn :color="dataset.tabIndex != 1 ? 'mediumGray' : 'primary'" outlined
                            @click="dataset.tabIndex = 1">
                            Detalhes
                            <v-icon right>mdi-format-list-text</v-icon>
                        </v-btn>
                        <v-btn outlined @click="dataset.tabIndex = 2"
                            :color="dataset.tabIndex != 2 ? 'mediumGray' : 'primary'">
                            Documentos
                            <v-icon right>mdi-file-document-outline</v-icon>
                        </v-btn>
                        <v-btn outlined @click="dataset.tabIndex = 3"
                            :color="dataset.tabIndex != 3 ? 'mediumGray' : 'primary'">
                            Credores
                            <v-icon right>mdi-account-multiple-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-form v-if="dataset.tabIndex == 1" ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">
                                    Detalhes

                                    <v-spacer></v-spacer>

                                    <div v-if="verifyPendingEvents()" class="icon-container">
                                    
                                        <template>
                                            <v-tooltip bottom>
                                                <template #activator="{ on }">
                                                    <v-icon icon v-on="on" size="large" class="mx-1"
                                                        @click="generateFinalReport()">
                                                        mdi-chart-bar
                                                    </v-icon>
                                                </template>
                                                <span>Pdf relatório final</span>
                                            </v-tooltip>
                                        </template>

                                        <template>
                                            <v-tooltip bottom>
                                                <template #activator="{ on }">
                                                    <v-icon icon v-on="on"
                                                        size="large" class="mx-1" @click="generateNoticeReportPdf()">
                                                        mdi-file-document-outline
                                                    </v-icon>
                                                </template>
                                                <span>Pdf edital</span>
                                            </v-tooltip>
                                        </template>

                                        <template>
                                            <v-tooltip bottom>
                                                <template #activator="{ on }">
                                                    <v-icon icon v-on="on"
                                                        size="large" class="mx-1" @click="generateNoticeReportDocx()">
                                                        mdi-file-word-outline
                                                    </v-icon>
                                                </template>
                                                <span>Documento edital</span>
                                            </v-tooltip>
                                        </template>

                                        <template>
                                            <v-tooltip bottom>
                                                <template #activator="{ on }">
                                                    <v-icon icon v-on="on"
                                                        size="large" class="mx-1" @click="generateLetterAnalysisReportPdf()">
                                                        mdi-chart-box-outline
                                                    </v-icon>
                                                </template>
                                                <span>Pdf análise de ofício</span>
                                            </v-tooltip>
                                        </template>

                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Número do processo"
                                                v-model="dataset.data.process_number" required outlined
                                                hide-details="auto" :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense label="Vara" v-model="dataset.data.local" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select no-data-text="Nenhuma comarca encontrada" dense
                                                :items="dataset.districts" label="Comarca"
                                                v-model="dataset.data.district_id" item-value="id" item-text="name"
                                                outlined :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select no-data-text="Nenhuma UF encontrada" dense :items="dataset.states"
                                                label="UF" v-model="dataset.data.state" item-value="id" item-text="name"
                                                outlined :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense label="Email de acesso" v-model="dataset.data.email" required
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense label="Número de credores"
                                                v-model="dataset.data.total_creditors" required
                                                :rules="[$rules.required, $rules.number]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        
                                        <v-col cols="12" sm="4">
                                            <v-currency-field label="Valor total devido" dense
                                                v-model="dataset.data.total_value" :allowNegative=false
                                                hide-details="auto" outlined required :rules="[$rules.required]">
                                            </v-currency-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select no-data-text="Nenhuma colaborador encontrado" dense
                                                :items="dataset.employees" label="Colaborador responsável"
                                                v-model="dataset.data.employee_id" item-value="id" item-text="name"
                                                outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Termo legal"
                                                :value="formatDate(dataset.data.legal_term)" @input="updateLegalTerm"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="CNPJ" v-model="dataset.data.registration"
                                                required v-mask="this.$masks.cnpj" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.clientTypes" label="Tipo"
                                                v-model="dataset.data.type" :rules="[$rules.required]" item-value="id"
                                                item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Ajuizamento"
                                                :value="formatDate(dataset.data.judgment_at)" @input="updateJudgment"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Deferimento"
                                                :value="formatDate(dataset.data.deferment_at)" @input="updateDeferment"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Início do prazo de hab/div"
                                                :value="formatDate(dataset.data.start_event_at)"
                                                @input="updateStartEvent" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Fim do prazo de hab/div"
                                                :value="formatDate(dataset.data.end_event_at)" @input="updateEndEvent"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Declaração da Insolvência"
                                                :value="formatDate(dataset.data.insolvency_at)"
                                                @input="updateInsolvency" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Decretação da Quebra/Liquidação"
                                                :value="formatDate(dataset.data.liquidation_at)" @input="updateLiquidation"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-file-input dense accept="image/png, image/jpeg" prepend-icon="" outlined
                                                counter :rules="[$rules.fileSize(15)]"
                                                hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                                label="Banner (destaque)" :clearable="true"
                                                @change="onFileSelected('fileBanner', $event)" ref="logoFile">
                                            </v-file-input>

                                            <template v-if="dataset.data.banner_url">
                                                <v-img @click="openDoc(dataset.data.banner_url)" class="rounded-xl"
                                                    :height="100" :width="100" :src="dataset.data.banner_url"></v-img>

                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-btn v-on="on" class="mt-4" type="button" elevation="0"
                                                            color="primary" dark
                                                            @click="deleteFile('banner')"><v-icon>mdi-delete</v-icon>
                                                            Excluir</v-btn>
                                                    </template>
                                                    <span>Excluir banner</span>
                                                </v-tooltip>
                                            </template>
                                        </v-col>

                                        <v-col cols="6">
                                            <v-file-input dense accept="image/png, image/jpeg" prepend-icon="" outlined
                                                counter :rules="[$rules.fileSize(15)]"
                                                hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                                label="Logo" :clearable="true"
                                                @change="onFileSelected('fileLogo', $event)" ref="logoFile">
                                            </v-file-input>

                                            <template v-if="dataset.data.logo_url">
                                                <v-img @click="openDoc(dataset.data.logo_url)" class="rounded-xl"
                                                    :height="100" :width="100" :src="dataset.data.logo_url"></v-img>
                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-btn v-on="on" class="mt-4" type="button" elevation="0"
                                                            color="primary" dark
                                                            @click="deleteFile('logo')"><v-icon>mdi-delete</v-icon>
                                                            Excluir</v-btn>
                                                    </template>
                                                    <span>Excluir logo</span>
                                                </v-tooltip>
                                            </template>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Descrição</p>
                                            <tiptap-vuetify v-model="dataset.data.description" :extensions="extensions"
                                                hide-details="auto" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <template v-if="dataset.tabIndex == 2">
            <v-row>
                <v-col cols="12">
                    <v-card class="filter-card">
                        <v-card-title class="filter-card-title">Documentos</v-card-title>
                        <v-card-text>


                            <v-row>
                                <v-col cols="12">
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-btn v-on="on" id="btnFilter" @click="openAddDocumentModal(dataset.data)"
                                                color="primary" dark elevation="2">Inserir
                                            </v-btn>
                                        </template>
                                        <span>Inserir documento</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-container fluid justify-center>

                    <v-card class="elevation-1 rounded-lg my-4">
                        <v-card-title class="card-group-title mb-n3 mt-n7">Listar por:</v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-select dense :items="dataset.clientDocTypes" no-data-text=""
                                        label="Tipo de Documento" v-model="dataset.selectedDocType" item-value="id"
                                        item-text="name" outlined @change="onChangeDocType()" hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <v-flex class="elevation-1">
                        <v-toolbar class="mb-2" color="primary" dark>
                            <v-toolbar-title>Lista de Documentos / Ordem de Apresentação</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="action('edit')" v-if="!editing" class="mr-5">
                                <v-icon>mdi-sort</v-icon>
                                Ordenar
                            </v-btn>
                            <v-btn icon @click="action('done')" v-if="editing" class="mr-10">
                                <v-icon>mdi-check</v-icon>
                                Salvar
                            </v-btn>
                            <v-btn icon @click="action('undo')" v-if="editing" class="mr-10 ml-10">
                                <v-icon>mdi-close</v-icon>
                                Desfazer
                            </v-btn>
                        </v-toolbar>
                        <v-list one-line>
                            <draggable :options="options" v-model="dataList.docsFilter">

                                <template v-for="(v, i) in dataList.docsFilter">
                                    <v-list-item :key="v.id">
                                        <v-list-item-avatar color="grey">
                                            <span style="user-select:none;">{{ i + 1 }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="v.name"></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <div class="d-flex">
                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-btn v-on="on" icon @click="deleteDocument(v)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Excluir documento</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>

                                                    <template #activator="{ on }">
                                                        <v-btn v-on="on" icon @click="openEditDocumentModal(v)">
                                                            <v-icon>mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Editar documento</span>
                                                </v-tooltip>
                                            </div>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </draggable>
                        </v-list>
                    </v-flex>
                </v-container>
            </v-row>

            <client-doc-details-modal ref="clientDocDetailsModalRef"></client-doc-details-modal>

        </template>

        <template v-if="dataset.tabIndex == 3">
            <v-row>
                <v-col cols="12">
                    <v-card class="table-card">
                        <v-card-title class="table-card-title">Credores</v-card-title>
                        <v-card-text>
                            <v-data-table :headers="dataList.headers" :items="dataList.data"
                                :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                                @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                                no-data-text="Nenhum caso foi encontrado" loading-text="Carregando dados..."
                                class="app-table hover-table" :options.sync="dataList.options"
                                :footer-props="dataList.footerOptions">

                                <template v-slot:[`item.created_at`]="{ item }">
                                    {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                                </template>

                                <template v-slot:[`item.registration`]="{ item }">
                                    {{ formatCpfCnpj(item.registration) }}
                                </template>

                                <template v-slot:[`item.amount`]="{ item }">
                                    R$ {{ item.amount | currency }}
                                </template>

                                <template v-slot:[`item.type`]="{ item }">
                                    {{ item.type | enum(dataset.creditorTypes, "id", "name") }}
                                </template>

                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <v-dialog v-model="dialogViewDoc" max-width="60%">
            <v-card>
                <v-img :src="selectedDoc" alt="" contain />
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import states from "@/common/data/states.data"
import string from "@/common/util/string"
import object from "@/common/util/object"

import ClientDocDetailsModal from "@/components/ClientDocDetailsModal.vue"

import clientTypes from "@/common/data/client-types.data"
import creditorTypes from "@/common/data/creditor-types.data"
import clientDocTypes from "@/common/data/client-doc-types.data"

import { cpf, cnpj } from 'cpf-cnpj-validator'

import axios from "axios"

import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph
} from 'tiptap-vuetify'

import { eventHub } from "@/main";
import draggable from 'vuedraggable';

export default {
    name: "ClientDetails",

    components: {
        'tiptap-vuetify': TiptapVuetify,
        'client-doc-details-modal': ClientDocDetailsModal,
        draggable,
    },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        items: [
            { id: null, client_id: null, order: null }
        ],
        editing: false,
        before: null,
        dataset: {
            clientTypes: clientTypes,
            creditorTypes: creditorTypes,
            clientDocTypes: clientDocTypes,
            breadcrumbItems: [],
            states: states,
            data: {
                judgment_at: null,
                deferment_at: null,
                insolvency_at: null,
                liquidation_at: null,
                end_event_at: null,
                start_event_at: null,
                legal_term: null
            },
            districts: [],
            employees: [],
            menuLegalTerm: false,
            menuJudgment: false,
            menuDeferment: false,
            menuStartEvent: false,
            menuEndEvent: false,
            selectedDocType: null,
            tabIndex: 1,
            loading: false
        },
        selectedDoc: null,
        dialogViewDoc: false,
        dialogDocDetails: false,
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
        },
        extensions: [
            Underline,
            Strike,
            Italic,
            Bold,
            Paragraph,
        ],

        dataList: {
            filter: {
                name: "",
                status: "",
                type: "",
                district: ""
            },
            docsFilter: [],

            headers: [
                { text: "Nome", value: "name" },
                { text: "CPF/CNPJ", value: "registration" },
                { text: "Valor", value: "amount" },
                { text: "Classe", value: "type" },
                { text: "Criado em", value: "created_at" },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
            }
        },
    }),

    created() {
        this.loadData()

        eventHub.$on('DIALOG-CLIENT-DOC-DETAILS', value => {
            this.dialogDocDetails = value;
        });
    },

    computed: {
        options() {
            return {
                disabled: !this.editing
            }
        },
    },

    methods: {

        formatDate(date) {
            if (!date || date === "0000-00-00 00:00:00") return null;
            return this.$moment(date).format('YYYY-MM-DD');
        },

        updateLegalTerm(value) {
            this.dataset.data.legal_term = value;
        },

        updateJudgment(value) {
            this.dataset.data.judgment_at = value;
        },

        updateDeferment(value) {
            this.dataset.data.deferment_at = value;
        },

        updateStartEvent(value) {
            this.dataset.data.start_event_at = value;
        },

        updateEndEvent(value) {
            this.dataset.data.end_event_at = value;
        },

        updateInsolvency(value) {
            this.dataset.data.insolvency_at = value;
        },

        updateLiquidation(value) {
            this.dataset.data.liquidation_at = value;
        },

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

        goBack() {
            this.$router.replace("/casos");
        },

        openDoc(doc) {
            this.selectedDoc = doc;
            this.dialogViewDoc = true;
        },

        pageChanged(page) {
            console.log('page changed ', page)
            this.dataList.options.page = page;
            this.loadCreditors();
        },

        pageSizeChanged(size) {
            this.dataList.options.size = size;
            this.loadCreditors();
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();

            await this.loadDistricts()
            await this.loadEmployees()
            await this.load();

            await this.loadCreditors();
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? `Novo caso` : `Detalhes do caso`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Listagem de casos",
                disabled: false,
                to: "/casos",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? `Incluir` : `Detalhes do caso`,
                disabled: true,
                exact: true,
            });
        },

        onChangeDocType() {

            switch (this.dataset.selectedDocType) {
                case 1:

                    this.dataList.docsFilter = this.dataset.data.documents
                        .filter(item => item.type === 1)
                        .sort((a, b) => a.order - b.order);

                    break;
                case 2:

                    this.dataList.docsFilter = this.dataset.data.documents
                        .filter(item => item.type === 2)
                        .sort((a, b) => a.order - b.order);

                    break;
                case 3:

                    this.dataList.docsFilter = this.dataset.data.documents
                        .filter(item => item.type === 3)
                        .sort((a, b) => a.order - b.order);

                    break;
                case 4:

                    this.dataList.docsFilter = this.dataset.data.documents
                        .filter(item => item.type === 4)
                        .sort((a, b) => a.order - b.order);

                    break;
                case 5:

                    this.dataList.docsFilter = this.dataset.data.documents
                        .filter(item => item.type === 5)
                        .sort((a, b) => a.order - b.order);

                    break;
                case 6:

                    this.dataList.docsFilter = this.dataset.data.documents
                        .filter(item => item.type === 6)
                        .sort((a, b) => a.order - b.order);

                    break;
                    
                default:
                    break;
            }

        },

        action(e) {
            switch (e) {
                case 'edit':
                    this.before = Object.assign([], this.dataList.docsFilter);
                    break;
                case 'undo':
                    this.dataList.docsFilter = this.before;
                    break;
                case 'done':
                    this.saveDocumentOrder()
                    break;
                default:
                    break;
            }

            this.editing = !this.editing
        },

        async saveDocumentOrder() {

            const data = this.dataList.docsFilter.map(item => ({
                id: item.id,
                client_id: item.client_id,
                order: item.order
            }))

            data.forEach((item, index) => {
                item.order = index + 1;
            });

            try {

                const response = await axios.patch(`/api/admin/client-docs/order/${data[0].client_id}`, { list: data });

                this.$dialog.notify.info("Nova ordenação salva com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 2000 })
            }
        },

        openEditDocumentModal(item) {

            this.$refs.clientDocDetailsModalRef.setData(this.dataset.data, item);

            this.$refs.clientDocDetailsModalRef.open().then(async (results) => {
                await this.load();
                this.onChangeDocType();
            });
        },

        async deleteDocument(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja continuar?',
                title: 'Excluir documento',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                const result = await axios.delete(`/api/admin/client-docs/${item.id}`)

                this.$dialog.notify.info("Documento excluído com sucesso!", { position: "top-right", timeout: 2000 });

                await this.load()

                this.onChangeDocType()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }
        },

        buildPayloadDocument() {

            const lastValue = this.items.reduce((max, obj) => (obj.order > max ? obj.order : max), -Infinity);

            const data = {
                order: isFinite(lastValue) ? lastValue + 1 : 1,
                client: this.dataset.data.client_id
            }

            return data;
        },

        async openAddDocumentModal(item) {

            this.$refs.clientDocDetailsModalRef.setData(item);

            this.$refs.clientDocDetailsModalRef.open().then(async (results) => {
                await this.load();
                this.onChangeDocType();
            });
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/clients/${this.$route.params.id}`);

                const data = result.data.content;

                this.dataset.data = {
                    ...data,
                    judgment_at: data.judgment_at !== '0000-00-00 00:00:00' ?this.$moment(data.judgment_at).utc(false) : null,
                    deferment_at: data.deferment_at !== '0000-00-00 00:00:00' ? this.$moment(data.deferment_at).utc(false) : null,
                    start_event_at: data.start_event_at !== '0000-00-00 00:00:00' ? this.$moment(data.start_event_at).utc(false) : null,
                    end_event_at: data.end_event_at !== '0000-00-00 00:00:00' ? this.$moment(data.end_event_at).utc(false) : null,
                    legal_term: data.legal_term !== '0000-00-00 00:00:00' ? this.$moment(data.legal_term).utc(false): null,
                    insolvency_at: data.insolvency_at !== '0000-00-00 00:00:00' ? this.$moment(data.insolvency_at).utc(false) : null,
                    liquidation_at: data.liquidation_at !== '0000-00-00 00:00:00' ? this.$moment(data.liquidation_at).utc(false) : null,
                };

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadCreditors() {

            if (this.page.isNew) return;

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            var page = this.dataList.options.page;

            try {
                const result = await axios.get(`/api/clients/creditors/${this.$route.params.id}`, { params: { page, limit, search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadDistricts() {

            try {
                const result = await axios.get(`/api/districts`, { params: { start: 1, limit: 100000, search: {} } });

                this.dataset.districts = result.data.content.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadEmployees() {

            try {
                const result = await axios.get(`/api/employees`, { params: { start: 1, limit: 100000, search: {} } });

                this.dataset.employees = result.data.content.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o caso?',
                title: 'Incluir caso',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/clients', payload);

                this.$dialog.notify.info("Caso salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o caso?',
                title: 'Atualizar caso',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/clients/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Cliente salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async deleteFile(file) {

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja continuar?',
                title: 'Excluir arquivo',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            try {

                const result = await axios.delete(`/api/admin/clients/${this.$route.params.id}/file/${file}`)

                this.$dialog.notify.info("Arquivo excluído com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            }

        },

        verifyPendingEvents() {
            if (!this.dataset.data || !this.dataset.data.events) return false;

            const result = this.dataset.data.events.every((x) => x.status != 0);

            return result
        },

        onFileSelected(field, file) {
            this.dataset.data[field] = file;
        },

        async generateFinalReport() {

            this.dataset.loading = true;

            try {
                const response = await axios.post('/api/admin/user-events/final-report-pdf', { client_id: this.dataset.data.id });

                this.$dialog.notify.info("Pdf relatório gerado com sucesso!", { position: "top-right", timeout: 2000 });
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async generateNoticeReportPdf() {

            this.dataset.loading = true;
            
            try {
                const result = await axios.get(`/api/admin/user-events/notice-report-pdf/${this.dataset.data.id}`, { responseType: 'arraybuffer' });

                let blob = new Blob([result.data], { type: 'application/pdf' });

                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Edital.pdf'
                link.click()

                this.$dialog.notify.info("Pdf edital gerado com sucesso!", { position: "top-right", timeout: 2000 });
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async generateNoticeReportDocx() {

            this.dataset.loading = true;
            
            try {
                const result = await axios.get(`/api/admin/user-events/notice-report-docx/${this.dataset.data.id}`, { responseType: 'arraybuffer' });

                let blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Edital.docx'
                link.click()

                this.$dialog.notify.info("Documemnto edital gerado com sucesso!", { position: "top-right", timeout: 2000 });
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async generateLetterAnalysisReportPdf() {

            this.dataset.loading = true;

            try {
                const response = await axios.post('/api/admin/user-events/letter-analysis-report-pdf', { client_id: this.dataset.data.id });

                this.$dialog.notify.info("Pdf da Análise de Ofício gerado com sucesso!", { position: "top-right", timeout: 2000 });
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },


        buildPayload() {
            const formData = new FormData()

            if (this.dataset.data.description)
                formData.append('description', this.dataset.data.description);

            if (this.dataset.data.name)
                formData.append('name', this.dataset.data.name);

            if (this.dataset.data.local)
                formData.append('local', this.dataset.data.local);

            if (this.dataset.data.email)
                formData.append('email', this.dataset.data.email);

            if (this.dataset.data.state)
                formData.append('state', this.dataset.data.state);

            if (this.dataset.data.process_number)
                formData.append('process_number', this.dataset.data.process_number);

            if (this.dataset.data.total_creditors)
                formData.append('total_creditors', this.dataset.data.total_creditors);

            if (this.dataset.data.total_value)
                formData.append('total_value', this.dataset.data.total_value);

            if (this.dataset.data.registration)
                formData.append('registration', this.dataset.data.registration);

            if (this.dataset.data.type)
                formData.append('type', this.dataset.data.type);

            if (this.dataset.data.legal_term)
                formData.append('legal_term', this.$moment(this.dataset.data.legal_term).format('YYYY-MM-DD'));

            if (this.dataset.data.start_event_at)
                formData.append('start_event_at', this.$moment(this.dataset.data.start_event_at).format('YYYY-MM-DD'));
            
            if (this.dataset.data.end_event_at)
                formData.append('end_event_at', this.$moment(this.dataset.data.end_event_at).format('YYYY-MM-DD'));

            if (this.dataset.data.judgment_at)
                formData.append('judgment_at', this.$moment(this.dataset.data.judgment_at).format('YYYY-MM-DD'));

            if (this.dataset.data.deferment_at)
                formData.append('deferment_at', this.$moment(this.dataset.data.deferment_at).format('YYYY-MM-DD'));

            if (this.dataset.data.insolvency_at)
                formData.append('insolvency_at', this.$moment(this.dataset.data.insolvency_at).format('YYYY-MM-DD'));

            if (this.dataset.data.liquidation_at)
                formData.append('liquidation_at', this.$moment(this.dataset.data.liquidation_at).format('YYYY-MM-DD'));

            if (this.dataset.data.district_id)
                formData.append('district_id', this.dataset.data.district_id);

            if (this.dataset.data.employee_id)
                formData.append('employee_id', this.dataset.data.employee_id);

            if (this.dataset.data.fileLogo)
                formData.append('files[]', this.dataset.data.fileLogo, 'logo')

            if (this.dataset.data.fileBanner)
                formData.append('files[]', this.dataset.data.fileBanner, 'banner')

            return formData;
        },


        async generateReportPdf(){

            const payload = {
                client_id: 9
            }

            console.log("entrou em gerar Report")

            try {
                const response = await axios.post(`/api/admin/user-events/final-report-pdf/'${this.dataset.data.id}`, payload);

                this.$dialog.notify.info("PDF do  Relatório gerado com sucesso!", { position: "top-right", timeout: 2000 });

               // this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } 


        },

        async generateNoticeDocx(){

            const payload = {
                client_id: 9
            }

            console.log("entrou em gerar Notice DOCX")

            try {
                const response = await axios.post(`/api/admin/user-events/notice-docx/'${this.dataset.data.id}`, payload);

                this.$dialog.notify.info("DOCX do Edital gerado com sucesso!", { position: "top-right", timeout: 2000 });

            // this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } 


        },


        async generateNoticePdf(){

            console.log("entrou em gerar Edital")

            try {
                const response = await axios.get(`/api/admin/user-events/notice-pdf/'${this.dataset.data.id}`, { data: {client_id: 10 }});

                this.$dialog.notify.info("PDF do edital gerado com sucesso!", { position: "top-right", timeout: 2000 });

               // this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } 


        },



    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}

.checkbox-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>